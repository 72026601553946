<template>
  <div id="accept-modal-preview" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="p-5 text-center">
            <CheckCircleIcon class="w-16 h-16 text-cyan-500 dark:text-theme-30 mx-auto mt-3" />
            <div class="text-3xl mt-5">
              {{ i18n('common.acceptModal.areYouSure') }}
            </div>
            <div class="text-gray-600 mt-2 dark:text-gray-400">
              <app-i18n code="common.acceptModal.wantToAccept" :args="[item]"></app-i18n>
              <br />{{ i18n(`common.acceptModal.cantBeUndone`) }}
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button type="button" data-dismiss="modal" class="btn bg-cyan-500 text-white w-24" @click="doSubmit()">
              {{ i18n(`common.accept`) }}
            </button>
            <button type="button" data-dismiss="modal" @click="$emit('close')"
              class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 ml-1 mr-1">
              {{ i18n(`common.cancel`) }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'accept-modal',
  props: {
    item: {
      type: String
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  methods: {
    doSubmit() {
      this.$emit('accept')
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>

<style>
</style>
