<template>
  <div id="reject-modal-preview" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="p-5 text-center">
            <XCircleIcon class="w-16 h-16 text-theme-24 mx-auto mt-3" />
            <div class="text-3xl mt-5">
              {{ i18n('common.rejectModal.areYouSure') }}
            </div>
            <div class="text-gray-600 mt-2 dark:text-gray-400">
              <app-i18n code="common.rejectModal.wantToReject" :args="[item]"></app-i18n>
              <br />{{ i18n(`common.rejectModal.cantBeUndone`) }}
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button type="button" data-dismiss="modal" class="btn btn-danger w-24" @click="doSubmit()">
              {{ i18n(`common.reject`) }}
            </button>
            <button type="button" data-dismiss="modal" @click="$emit('close')" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 ml-1 mr-1">
              {{ i18n(`common.cancel`) }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'reject-modal',
  props: {
    item: {
      type: String
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  methods: {
    doSubmit() {
      this.$emit('reject')
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>

<style>
</style>
