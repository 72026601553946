<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row mt-8">
      <h2 class="text-lg font-medium">{{ i18n('user.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Filter -->
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    ></TableFilter>
    <!-- END: HTML Table Filter -->

    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-2 sm:p-5 mt-5">

      <div ref="table" class="relative">
        <loading-spinner type="half-circle" :duration="1000" :size="40" color="#4469A8" :loading="loading" />

        <div class="overflow-x-auto">
          <table id="tablePrint" ref="tableRef" class="table table-report sm:mt-2">
            <thead>
              <tr>
                <th class="whitespace-nowrap">#</th>
                <!-- <th class="whitespace-nowrap text-center" id="ignore-3">
                  <input class="form-check-input" type="checkbox" v-model="selectAll" @click="select" />
                </th> -->
                <th v-for="col in columns" :key="col.name" :id="col.name ==='action' ? 'ignore-1' : ''" class="text-center whitespace-nowrap">
                  {{ i18n(col.label) }}
                </th>
              </tr>
            </thead>

            <tbody v-if="rows.length">
              <tr v-for="(row, index) in rows" :key="index" class="intro-x">
                <td class="text-start">
                  <div class="text-gray-600 text-xs font-medium whitespace-nowrap mt-0.5">
                    {{ rowIndex(index) }}
                  </div>
                </td>
                <!-- <td id="ignore-4">
                  <div class="text-center">
                    <input class="form-check-input" type="checkbox" :value="row.id" v-model="selected" />
                  </div>
                </td> -->
                <!-- <td class="text-center flex items-center justify-center">
                  <app-list-item-image :value="row.avatar" type="user"></app-list-item-image>
                </td> -->
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'userName') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'email') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap" style="direction:ltr !important">
                    {{ presenter(row, 'phoneNumber') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterDate(row, 'createdAt') }}
                  </div>
                </td>
                <td class="text-center">
                  <div
                    class="flex items-center justify-center gap-2 font-medium whitespace-nowrap"
                    :class="{
                      'text-gray-600 dark:text-theme-30': row.status == 'pending',
                      'font-bold text-green-600': row.status == 'accepted',
                      'font-bold text-theme-24': row.status == 'rejected'
                    }"
                  >
                    <!-- <HelpCircleIcon class="w-4 h-4" v-if="row.status == 'pending'" /> -->
                    <CheckSquareIcon class="w-4 h-4" v-if="row.status == 'accepted'" />
                    <XSquareIcon class="w-4 h-4" v-if="row.status == 'rejected'" />
                    {{ i18n(`iam.enumerators.status.${row.status}`) }}
                  </div>
                </td>
                <td class="table-report__action w-56">
                  <div class="flex justify-center items-center gap-3 text-theme-24">
                    <!-- <router-link class="text-theme-10 dark:text-theme-30" :to="`/users/${row.id}`">
                      <Tippy tag="div" class="flex items-center gap-2" :content="i18n('common.view')">
                        <EyeIcon class="w-4 h-4" /> {{ i18n('common.view') }}
                      </Tippy>
                    </router-link> -->

                    <button type="button" class="text-theme-24" v-if="!row.isRemoved" @click="showModal('delete', row.id)">
                      <Tippy tag="div" class="flex items-center gap-2" :content="i18n('common.delete')">
                        <Trash2Icon class="w-5 h-5" />
                      </Tippy>
                    </button>

                    <button type="button" class="text-green-700" v-if="row.status == 'pending'" @click="showModal('accept', row.id)">
                      <Tippy tag="div" class="flex items-center gap-2" :content="i18n('common.accept')">
                        <CheckCircleIcon class="w-5 h-5" />
                      </Tippy>
                    </button>
                    <button type="button" class="text-theme-24" v-if="row.status == 'pending'" @click="showModal('reject', row.id)">
                      <Tippy tag="div" class="flex items-center gap-2" :content="i18n('common.reject')">
                        <XCircleIcon class="w-5 h-5" />
                      </Tippy>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-empty-page v-if="!rows.length && !loading"
            :label="i18n('empty.client')"
            label-class="text-lg font-medium"
            icon-size="4x"
            class="box w-full py-64"
          ></app-empty-page>
        </div>
        <Pagination v-if="rows.length"
          :page="currentPage"
          :pageSize="pagination.limit"
          :rowsNumber="pagination.total"
          :showSizeChanger="true"
          :disabled="!rows.length"
          position="center"
          @next-page="doNextPage"
          @prev-change="doPrevPage"
          @size-change="doChangePaginationPageSize"
        ></Pagination>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Delete Modal -->
    <DeleteModal item="user" @delete="doDestroy(selectedId)" @close="selectedId = null" />
    <AcceptedModal item="user" @accept="doAcceptUser({ id: selectedId })" @close="selectedId = null" />
    <RejectedModal item="user" @reject="doRejectUser({ id: selectedId })" @close="selectedId = null" />
    <!-- END: Delete Modal -->
  </div>
</template>

<script>
import DeleteModal from '@/components/modals/delete-modal.vue'
import AcceptedModal from '@/components/modals/accept-modal.vue'
import RejectedModal from '@/components/modals/reject-modal.vue'

import { defineComponent, ref, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import feather from 'feather-icons'
import { helper as $h } from '@/utils/helper'
import print from 'print-js'
import { GenericModel } from '@/shared/model/generic-model'

export default defineComponent({
  components: {
    DeleteModal,
    AcceptedModal,
    RejectedModal
  },
  setup() {
    const tableLimit = ref(10)
    const tableRef = ref()
    const tabulator = ref()
    const selected = ref([])
    const selectAll = ref(false)

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    onMounted(() => {
      reInitOnResizeWindow()
    })

    return {
      selected,
      selectAll,
      tableLimit,
      tableRef
    }
  },
  data() {
    return {
      selectedId: null,
      selectedUser: {},
      filterInput: [],
      columns: [
        // {
        //   name: 'avatar',
        //   field: 'avatar',
        //   label: 'iam.fields.avatar',
        //   align: 'center'
        //   // required: true,
        // },
        {
          name: 'fullName',
          field: 'fullName',
          label: 'iam.fields.fullName',
          align: 'center'
          // required: true,
        },
        {
          name: 'email',
          field: 'email',
          label: 'iam.fields.email',
          align: 'center'
          // required: true,
        },
        {
          name: 'phoneNumber',
          field: 'phoneNumber',
          label: 'iam.fields.phoneNumber',
          align: 'center'
          // required: true,
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'iam.fields.joinDate',
          align: 'center'
          // required: true,
        },
        {
          name: 'status',
          field: 'status',
          label: 'iam.fields.status',
          align: 'center'
          // required: true,
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'user/list/rows',
      pagination: 'user/list/pagination',
      listLoading: 'user/list/loading',
      saveLoading: 'user/form/saveLoading',
      destroyLoading: 'user/destroy/loading'
    }),
    loading() {
      return this.listLoading || this.destroyLoading || this.saveLoading
    },
    currentPage() {
      return this.pagination.currentPage || 1
    },
    rowIndex() {
      return (index) => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    },
    statusOptions() {
      return [
        { label: this.i18n('iam.enumerators.status.accepted'), value: 'accepted' },
        { label: this.i18n('iam.enumerators.status.rejected'), value: 'rejected' },
        { label: this.i18n('iam.enumerators.status.pending'), value: 'pending' }
      ]
    },
    filterFields() {
      return [
        { label: this.i18n('iam.fields.fullName'), value: 'fullName', type: 'text' },
        { label: this.i18n('iam.filter.phoneNumber'), value: 'phoneNumber', type: 'text' },
        { label: this.i18n('iam.filter.email'), value: 'email', type: 'text' },
        { label: this.i18n('iam.filter.status'), value: 'status', type: 'list', options: this.statusOptions }
      ]
    }
  },
  created() {
    document.title = this.i18n('app.title') + ' | ' + this.i18n('routes.users')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('app.title') + ' | ' + this.i18n('routes.users')
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'user/list/doFetch',
      doFetchNextPage: 'user/list/doFetchNextPage',
      doFetchPreviousPage: 'user/list/doFetchPreviousPage',
      doMountTable: 'user/list/doMountTable',
      doChangeSort: 'user/list/doChangeSort',
      doChangePaginationCurrentPage: 'user/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'user/list/doChangePaginationPageSize',
      doToggleUserAccountStatus: 'user/view/doToggleUserAccountStatus',
      doDestroy: 'user/destroy/doDestroyUser',
      doAcceptUser: 'user/form/doAcceptUser',
      doRejectUser: 'user/form/doRejectUser'
    }),
    select() {
      this.selected = []
      if (!this.selectAll) {
        this.rows.forEach((element) => {
          this.selected.push(element.id)
        })
      }
    },
    clearSelection() {
      this.selected = []
      this.selectAll = false
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    showModal(modalName, id) {
      this.selectedId = id
      switch (modalName) {
        case 'accept':
          cash('#accept-modal-preview').modal('show')
          break
        case 'reject':
          cash('#reject-modal-preview').modal('show')
          break
        case 'delete':
          cash('#delete-modal-preview').modal('show')
          break
      }
    },
    async doFilter(filter) {
      this.filterInput = []
      if (!filter || !filter.value) return
      if (Array.isArray(filter)) {
        this.filterInput.push(...filter)
      } else {
        this.filterInput.push(filter)
      }
      await this.doFetchFirstPage()
    },
    async doResetFilter() {
      this.filterInput = []
      await this.doFetchFirstPage()
    },
    async doEnableAllSelected() {
      await this.doEnableAll(this.selected)
      this.clearSelection()
    },
    async doDisableAllSelected() {
      await this.doDisableAll(this.selected)
      this.clearSelection()
    },
    async doDestroyAllSelected() {
      await this.doDestroyAll(this.selected)
      this.clearSelection()
    },
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        type: 'user',
        filter: this.filterInput,
        pagination: {
          firstPage: true,
          limit: 10,
          action: 'next',
          orderBy: 'createdAt',
          sortType: 'desc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    onExportXlsx() {
      $h.exportToXlsx(this.tableRef, 'userTable', 'users')
    },
    onDownloadPdf() {
      $h.downloadPdf('#tablePrint', 'users', [1, 2, 8])
    },
    onPrint() {
      print({
        printable: 'tablePrint',
        type: 'html',
        scanStyles: true,
        targetStyles: ['*'],
        maxWidth: 150,
        ignoreElements: ['ignore-1', 'ignore-2', 'ignore-3', 'ignore-4']
      })
    }
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}
.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}
.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
